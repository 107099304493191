.App {
  font-family: Poppins, sans-serif;
  text-align: center;
  background-color: #03001F;
  min-height: 100vh;
}

.App-header {
  background-blend-mode: soft-light;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-logo {
  margin-top: calc(4rem + 8vw);
  pointer-events: none;
  height: 8rem;
}

.App-title {
  font-family: Nunito, sans-serif;
  font-size: calc(3rem + 3vmin);
  font-weight: normal;
  margin-top: 0;
  margin-bottom: 0;
  letter-spacing: 0.1em;
}

.App-link {
  color: #61dafb;
}

.App-footer {
  font-size: 0.875rem;
  letter-spacing: 0.05rem;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 0;
  color: #fafafa;
}